import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const Overlay = styled.div`
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
`;

const BgImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: ${(props) => props.height || '70vh'};
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: ${(props) => props.fit || 'cover'} !important;
    object-position: ${(props) => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${(props) => props.fit || 'cover'} !important; object-position: ${(props) => props.position || '50% 50%'} !important;'
  }

  @media (max-width: 768px) {
    height: 50vh;
  }
`;

export function Hero(props) {
  const { children, fluid } = props;
  return (
    <Container>
      <BgImage fluid={fluid} />
      <Overlay>{children}</Overlay>
    </Container>
  );
}

const StyledBox = styled.div`
  color: #fff;
  letter-spacing: 10px;
  font-size: 72px;
  line-height: 80px;
  font-weight: 100;
  text-transform: uppercase;
  font-family: "Varela Round"
  width: 70vw;

  @media (max-width: 768px) {
    font-size: 20px;
  }

`;

export function HeroText(props) {
  const { text } = props;
return (
    <StyledBox cursor={{ show: false }}>
    <strong>{text}</strong>
    <br />
    </StyledBox>
);
}
